import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import io from 'socket.io-client';
import { BASEURL } from '@config';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initBackendAndElectronSocket } from './socket.io/init';
const lang = window.localStorage.getItem('kiosk_selected_language');

if (window.structuredClone) {
  // no op
} else {
  window.structuredClone = (data) => JSON.parse(JSON.stringify(data));
}

if (lang && lang === 'ar') {
  import('@assets/css/arabic.css').then((condition) => {});
  // moment.locale('ar');
} else {
  import('@assets/css/App.css').then((condition) => {});
  // moment.locale('en');
}

let electron_server_url = 'http://localhost:3001';

let socket_options = {
  reconnectionAttempts: 3,
  reconnectionDelay: 10000,
  transports: ['websocket'],
};

export const backendSocket = io(BASEURL + '/', socket_options);
export const electronSocket = io(electron_server_url, socket_options);

initBackendAndElectronSocket(backendSocket, electronSocket);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
