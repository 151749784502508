import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export function useInterval(callback, delay) {
  const savedCallback = useRef(callback);

  let [state, setState] = useState('');

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return () => {};
    }

    const id = setInterval(() => {
      if (savedCallback?.current?.then) {
        savedCallback
          .current({ id, delay })
          .then((res) => {
            setState(res || '');
          })
          .catch((e) => {
            console.log('Error in useInterval', e);

            setState(e.message);
          });
      } else {
        setState(savedCallback?.current?.({ id, delay }) || '');
      }
    }, delay);

    return () => clearInterval(id);
  }, [delay]);

  return [state];
}
