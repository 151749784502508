import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';

const selectedFnbItemsSelector = (state) =>
  state.foodAndBeverages.selected_fnb_items;

const fnbItemsInStore = (state) => state.foodAndBeverages.fnb_items;

const fnbItemsWithSearch = (state, search, selectedCategory) => [
  state.foodAndBeverages.fnb_items,
  search,
  selectedCategory,
];
export const modifiersForSelectedFnbItems = createSelector(
  selectedFnbItemsSelector,
  (selectedFnbItems) => {
    let concessionModifiersMapping = {};
    for (let item of selectedFnbItems) {
      concessionModifiersMapping[item.Id] = [];
      for (let modifierArr of item.Modifiers) {
        for (let modifier of modifierArr) {
          let modifierIndex = concessionModifiersMapping[item.Id].findIndex(
            (x) => x.Id === modifier.Id,
          );
          if (modifierIndex !== -1) {
            concessionModifiersMapping[item.Id][modifierIndex].Quantity +=
              modifier.Quantity;
            concessionModifiersMapping[item.Id][modifierIndex].priceInCents +=
              modifier.priceInCents;
            concessionModifiersMapping[item.Id][modifierIndex].taxesInCents +=
              modifier.taxInCents;
          } else {
            let modCopy = cloneDeep(modifier);
            concessionModifiersMapping[item.Id].push(modCopy);
          }
        }
      }
    }

    return concessionModifiersMapping;
  },
);
export const fnbItemsWithSearchSelector = createSelector(
  fnbItemsWithSearch,
  ([fnbItems, search, selectedCategory]) => {
    if (!fnbItems) return fnbItems;
    if (!search && !selectedCategory) return fnbItems;

    return fnbItems.filter((item) => {
      let nameMatches = true;
      let categoryMatches = true;

      if (search)
        nameMatches = item?.item_name
          ?.toLowerCase()
          .includes(search?.toLowerCase());

      if (selectedCategory)
        categoryMatches = item?.fc_category === selectedCategory?.fc_category;

      return !!nameMatches && !!categoryMatches;
    });
  },
);

export const fnbItemCategoriesSelector = createSelector(
  fnbItemsInStore,
  (fnbItems) => {
    let categories = new Set();

    if (fnbItems && fnbItems.length > 0) {
      for (let fnbItem of fnbItems) {
        if (fnbItem.fc_category) {
          categories.add(fnbItem.fc_category);
        }
      }
    }

    return Array.from(categories.keys());
  },
);
