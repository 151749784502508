import React, {
  Component,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  FunctionComponent,
  ChangeEvent,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Table,
  Alert,
  Modal,
  Spinner,
  Dropdown,
} from 'react-bootstrap';
import screen from '../../assets/imgs/svgs/screen.svg';
import ip1 from '../../assets/imgs/ip1.jpg';
import moment from 'moment';
import { PaymentService } from '@apiService/PaymentService';
import BookingService from '@apiService/BookingService';
import { withNamespaces } from 'react-i18next';
import i18n from '../../plugins/i18n';
import { SOCKET_URL } from '@config';
import clock from '@assets/imgs/svgs/clock.svg';
import * as yup from 'yup';
import KeyboardWrapper from '../KeyboardWrapper';
import { toast } from 'react-toastify';
import {
  bookSeatsOnDemand,
  bookFnb,
  fetchPaymentModes,
  verifyVoucherCode,
  makeCCAvenuePayment,
} from '@store/booking/bookingActions';
import MovieHeader from '@components/partials/MovieHeader';
import GoBack from '@components/partials/GoBack';
import { formatYuppErrors } from '@helper/formatting';
import { ConeStriped } from 'react-bootstrap-icons';
import { SET_CUST_MOB_CODE } from '../../store/global/globalTypes';
import { getLangSpecificAttribute } from '../../helper/Languages';
import { fetchReservationDetails } from '../../store/movies/moviesActions';
import { BASEURL } from '../../config';
import QRCode from 'qrcode.react';
import { useInterval } from '../../customHooks/useInterval';
import { SET_BOOKING_CODE, SET_BOOKING_ID } from '../../store/booking/bookingTypes';

// import { globalConfigContext } from '@context/GlobalConfigContext';
var wsUrl = SOCKET_URL;
var socket = new WebSocket(wsUrl);

const keyboardOptions = {
  layout: {
    default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 {enter}'],
    shift: ['! / #', '$ % ^', '& * (', '{shift} ) +', '{bksp}'],
  },
  theme: 'hg-theme-default hg-layout-numeric numeric-theme',
};

const CheckoutOnDemand = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [errors, setErrors] = useState(null);
  const [showPaymentModesModal, setShowPaymentModesModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const timer = useSelector((state) => state.booking.timer);
  const { reservation_id } = props.match.params;
  const allshowDetails = useSelector((state) => state.movies.show_times);
  const session_id = useSelector((state) => state.booking.session_id);
  // const showDetails = allshowDetails.find((show) => show.ss_id == session_id);
  const showDetails = useSelector((state) => state.movies.selected_session);
  let currentSetShow = useSelector((s) => s.movies.show);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showKeyboardVoucher, setShowKeyboardVoucher] = useState(false);
  const [showKeyboardMobile, setShowKeyboardMobile] = useState(false);
  const [showSearchKeyboard, setshowSearchKeyboard] = useState(false);
  const keyboard = useRef(null);
  const keyboardMobile = useRef(null);
  const keyboardSearch = useRef(null);
  const keyboardVoucher = useRef(null);
  const movies = useSelector((state) => state.movies.now_playing_movies);
  const [voucherCode, setVoucherCode] = useState(``);
  const [redirectData, setredirectData] = useState(null);
  const [checkBoookingCount, setCheckBookingCount] = useState(0);

  useInterval(
    () => {
      setCheckBookingCount(checkBoookingCount + 1);
      // check booking

      return BookingService.CheckBookingStatus(reservation_id)
        .then(({ data }) => {
          console.log({ data });
          if (data?.booking_code) {
            dispatch({ type: SET_BOOKING_ID, payload: data?.booking_code });
            dispatch({ type: SET_BOOKING_CODE, payload: data?.booking_code });
            // dispatch(setBookingDetails(data));
            history.push(`/final-ticket`);
          }
        })
        .catch((e) => {});
    },
    // Delay in milliseconds or null to stop it
    redirectData && redirectData.url ? 1000 * 10 : null,
  );

  // const movieDetails = movies.find(
  //   (movie) => movie.movie_id == showDetails.movie_id
  // );
  const selectedFnbItems = useSelector((state) => state.booking.selected_fnb_items);
  const showTimeoutPopup = useSelector((state) => state.booking.showTimeoutPopup);
  const paymentModes = useSelector((state) => state.booking.payment_modes);
  const t = props.t;
  const seats_string = useSelector((state) => state.booking.seats_string);
  const global = useSelector((state) => state.global);
  const booking_type = useSelector((state) => state.movies.booking_type);
  const privateTotalPrice = useSelector((state) => state.movies.totalAmount);

  const {
    currency: { curr_code, curr_id },
    cinema: { cinema_id },
    countries,
    cust_mob_code,
    dummy_image,
    mode,
    selected_language: { lang_id },
  } = global;
  const selectedTicketPrice = useSelector(
    (state) => state.booking.selectedTicketPrice,
  );
  let countriesCopy = useRef(countries);
  const [validEmail, setValidEmail] = useState('');
  const [validMobile, setValidMobile] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loader, setloader] = useState(false);
  const [selectedBtn, setselectedBtn] = useState('');
  const schema = yup.object().shape({
    // validEmail: yup.string().email().required(),
    validEmail: yup.string().email(),
    validMobile: yup.string().min(9).max(10).required(),
  });
  const [searchCountryCode, setSearchCountryCode] = useState('');
  useEffect(() => {
    dispatch(fetchPaymentModes());
  }, [reservation_id]);

  const onSearchCountry = (e) => {
    console.log('e', e);
    setSearchCountryCode(e);
    searchCountryFunction();
    console.log('cust_mob_code', cust_mob_code);
  };
  const searchCountryFunction = useCallback(() => {
    countriesCopy.current = countries.filter((country) => {
      if (
        country.country_mob_code &&
        country.country_mob_code
          .toLowerCase()
          .includes(searchCountryCode.toLowerCase())
      ) {
        return country;
      }
    });
  }, [searchCountryCode]);
  useEffect(() => {
    searchCountryFunction();
  }, [searchCountryCode]);
  const onProceed = () => {
    localStorage.removeItem('guest_detail');
    localStorage.removeItem('guest_id');
    localStorage.removeItem('mapped_cust_id');

    setSubmitted(true);
    schema
      .validate(
        {
          validEmail,
          validMobile,
        },
        { abortEarly: false },
      )
      .then((valid) => {
        console.log('in valid ');
        // dispatch(setUserDetails(email, `${countryCode}${mobile}`));
        // props.history.push('/process-payment');
        if (valid) {
          let guest_detail = {
            guest_email: validEmail,
            guest_first_name: '-',
            guest_last_name: '-',
            guest_mobile: validMobile,
            guest_country_code:
              `${cust_mob_code}`.replace(/\+/gim, '').trim() || '91',
            guest_user_id: reservation_id,
            isUpdateLang: false,
            selected_lang_id: 1,
            country_id: 191,
          };
          localStorage.setItem('guest_detail', JSON.stringify(guest_detail));

          return BookingService.GuestRegister(guest_detail)
            .then((res) => {
              setShowPaymentModesModal(true);
              return res;
            })
            .catch((e) => Promise.resolve({ status: false }));
        }

        return Promise.resolve({ status: false });
      })
      .then((resp) => {
        if (resp?.data?.status && resp?.data.data?.[0]?.guest_id) {
          let guest_id = resp?.data.data?.[0]?.guest_id;
          localStorage.setItem('guest_id', guest_id);

          let mapped_cust_id = resp?.data.data?.[0]?.mapped_cust_id || '';
          if (mapped_cust_id) {
            localStorage.setItem('mapped_cust_id', mapped_cust_id);
          }
        }
      })
      .catch((errs) => {
        let errors = formatYuppErrors(errs);
        setErrors(errors);
      });
  };
  const vouchers = useSelector((state) => state.booking.vouchers);
  const successfulVoucherCode = useSelector(
    (state) => state.booking.successfulVoucherCode,
  );
  const paymentModeHandler = (paymentMode, index) => {
    setloader(true);
    setselectedBtn(index);
    makePayment(paymentMode);
  };

  const validateVoucher = () => {
    if (voucherCode) {
      dispatch(
        verifyVoucherCode({
          voucherCode,
          vouchers,
          selectedTicketPrice,
          callback2: setloader,
        }),
      );
    } else {
      toast.error(`Please enter a voucher code`);
    }
  };

  const voucherDiscount = useSelector((state) => state.booking.voucherAmount);
  const reservationDetails = useSelector(
    (state) => state.movies?.reservationDetails,
  );
  let private_rate_card_detail = reservationDetails?.private_rate_card_detail ?? [];

  const onChangeInput = (event) => {
    const input = event.target.value;
    setValidEmail(input);
    keyboard.current.setInput(input);
    setErrors({ ...errors, validEmail: null });
  };

  const onChangeVoucherCodeInput = (event) => {
    const input = event.target.value;
    setVoucherCode(input);
    keyboardVoucher.current.setInput(input);
  };

  const onChangeSearchInput = (event) => {
    const input = event.target.value;
    setSearchCountryCode(input);
    keyboardSearch.current.setInput(input);
  };

  const onChangeInputMobile = (event) => {
    let input = event.target.value;
    if (/^0/.test(input)) {
      input = input.replace(/^0+/, '');
    }
    setValidMobile(input);
    keyboardMobile.current.setInput(input);
    setErrors({ ...errors, validMobile: null });
  };

  const onSelectCountryCode = (country) => {
    setSelectedCountry(country);
    let mobCode = country.country_mob_code;
    dispatch({ type: SET_CUST_MOB_CODE, payload: mobCode });
  };

  const makePayment = async (paymentMode) => {
    let guest_id = localStorage.getItem('guest_id');
    if (paymentMode.pm_id === 18) {
      let data = await dispatch(makeCCAvenuePayment({ reservation_id, guest_id }));

      console.log('redirectData', data);
      setredirectData(data);

      // keep checking of booking finished
    }
  };

  const history = useHistory();

  if (!reservationDetails?.altMovieContent) {
    console.log('Invalid Movie contents', { reservationDetails });
    history.push('/');
    return null;
  }

  let getArtwork = (artwork = '') => {
    let startWithUpload =
      artwork && (artwork.startsWith('/uploads') || artwork.startsWith('uploads'));

    if (startWithUpload) return `${BASEURL || ''}/${artwork}`;
    return artwork;
  };

  return (
    <>
      <MovieHeader
        onClick={() => {
          setShowKeyboard(false);
          setShowKeyboardMobile(false);
          setshowSearchKeyboard(false);
        }}
      />
      <Container fluid>
        <div
          className="d-flex justify-content-between align-items-center mt-3"
          onClick={() => {
            setShowKeyboard(false);
            setShowKeyboardMobile(false);
            setshowSearchKeyboard(false);
          }}
        >
          <GoBack to="prev" />
          <div className="session-timer">
            <span>
              {' '}
              {timer.minutes}:
              {timer.seconds < 10 ? '0' + timer.seconds : timer.seconds}
            </span>
            <img src={clock} className={''} />
          </div>
        </div>
        <Row
          className="checkout-page mt-4"
          onClick={() => {
            setShowKeyboard(false);
            setShowKeyboardMobile(false);
            setshowSearchKeyboard(false);
          }}
        >
          <Col className="col-8">
            <>
              {mode !== 2 && (
                <>
                  {' '}
                  <div className="pb-3">
                    <h5 className="heading">{t('checkout.TICKET')}</h5>
                  </div>
                  <Row className="ticket-header">
                    <Col className="col-3">
                      <img
                        src={
                          getArtwork(
                            getLangSpecificAttribute(
                              reservationDetails.altMovieContent,
                              lang_id,
                              'artwork',
                            ),
                          ) || dummy_image
                        }
                        alt="title"
                        className="img-fluid"
                      />
                    </Col>
                    <Col className="col-9 ml-n4">
                      <h4>
                        {getLangSpecificAttribute(
                          reservationDetails.altMovieContent,
                          lang_id,
                          'mc_title',
                        )}
                      </h4>
                      <div className="grid-50 pt-3">
                        <div>
                          <p>{t('common.Date')}</p>
                          <h4>
                            {moment(reservationDetails?.ss_start_date).format('LL')}
                          </h4>
                        </div>
                        <div>
                          <p>{t('seatlayout_page.Seats')}</p>
                          <h4>{seats_string}</h4>
                        </div>
                        <div>
                          <p>{t('seatlayout_page.Show Details')}</p>
                          <h4>
                            {reservationDetails?.screen_name},{' '}
                            {moment(
                              reservationDetails?.ss_start_show_time,
                              'HH:mm',
                            ).format('HH:mm')}
                          </h4>
                        </div>
                        {/* <div>
          <p>Voucher Code</p>
          <h4>
           ABCDS (20% OFF)
        </h4>
         </div> */}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          </Col>
          <Col className="col-4">
            <div className="brds-10 ticket-right">
              {mode !== 2 && booking_type != 3 && (
                <div className="ticket-right-box pb-2">
                  <h5 className="title pt-4 pb-3">{t('checkout.Tickets')}</h5>
                  <div className="grid-half pt-2">
                    <h5 className="left">
                      {!!seats_string ? seats_string : 'Private Screening'}
                    </h5>
                    <h5 className="right font-weight-bold">
                      {' '}
                      {curr_code}{' '}
                      {(
                        selectedTicketPrice.priceInCents -
                        selectedTicketPrice.totalWithFnb
                      ).toFixed(2)}
                    </h5>
                  </div>
                  <div className="grid-half pt-2">
                    <h5 className="left">Discount</h5>
                    <h5 className="right font-weight-bold">
                      {' '}
                      {curr_code} {voucherDiscount.toFixed(2)}
                    </h5>
                  </div>
                </div>
              )}
              {selectedFnbItems.length > 0 && (
                <div className="ticket-right-box pb-2">
                  <h5 className="title pt-4 pb-3">
                    {t('checkout.Food and Beverages')}
                  </h5>
                  {selectedFnbItems.map((item) => (
                    <div className="grid-half pt-2">
                      <h5 className="left">
                        {item.item_name} (x{item.Quantity})
                      </h5>
                      <h5 className="right font-weight-bold">
                        {curr_code}{' '}
                        {/* {parseFloat(item.fp_total_amount) * item.Quantity} */}
                        {(item.fp_total_amount * item.Quantity).toFixed(2)}
                      </h5>
                    </div>
                  ))}
                </div>
              )}
              {!!private_rate_card_detail?.length &&
                !!private_rate_card_detail[0]?.slab.length && (
                  <div className="ticket-right-box pb-2">
                    <h5 className="title pt-4 pb-3">{t('checkout.Tickets')}</h5>
                    {private_rate_card_detail[0].slab.map((item) => (
                      <div className="grid-half pt-2">
                        <h5 className="left">
                          {t('Private booking')} (x
                          {private_rate_card_detail[0].customSeatCount})
                        </h5>
                        <h5 className="right font-weight-bold">
                          {curr_code}{' '}
                          {/* {parseFloat(item.fp_total_amount) * item.Quantity} */}
                          {(
                            private_rate_card_detail[0].customSeatCount * item.price
                          ).toFixed(2)}
                        </h5>
                      </div>
                    ))}
                  </div>
                )}
              {!!private_rate_card_detail?.length &&
                !!private_rate_card_detail[0]?.fnb.length && (
                  <div className="ticket-right-box pb-2">
                    <h5 className="title pt-4 pb-3">
                      {t('checkout.Food and Beverages')}
                    </h5>
                    {private_rate_card_detail[0].fnb.map((item) => (
                      <div className="grid-half pt-2">
                        <h5 className="left">
                          {item.item_name} (x
                          {private_rate_card_detail[0].customSeatCount})
                        </h5>
                        <h5 className="right font-weight-bold">
                          {curr_code}{' '}
                          {/* {parseFloat(item.fp_total_amount) * item.Quantity} */}
                          {(
                            private_rate_card_detail[0].customSeatCount * item.price
                          ).toFixed(2)}
                        </h5>
                      </div>
                    ))}
                  </div>
                )}
              {!!private_rate_card_detail?.length &&
                !!private_rate_card_detail[0]?.addon.length && (
                  <div className="ticket-right-box pb-2">
                    <h5 className="title pt-4 pb-3">{t('Add Ons')}</h5>
                    {private_rate_card_detail[0].addon.map((item) => (
                      <div className="grid-half pt-2">
                        <h5 className="left">
                          {item.item_name} (x{item.quantity})
                        </h5>
                        <h5 className="right font-weight-bold">
                          {curr_code}{' '}
                          {/* {parseFloat(item.fp_total_amount) * item.Quantity} */}
                          {(item.quantity * item.price).toFixed(2)}
                        </h5>
                      </div>
                    ))}
                  </div>
                )}
            </div>
          </Col>
        </Row>
        <div className="checkout-footer">
          <div
            // onHide={handleClose}
            // size="lg"
            // aria-labelledby="contained-modal-title-vcenter"
            className="border-top"
          >
            <div className="brds-8 py-5">
              <div className="my-4 col-12">
                <>
                  <article className="row flex-column align-items-center">
                    <section className="col-6 d-flex align-items-center">
                      <input
                        type="text"
                        placeholder="Voucher Code"
                        className="rounded-lg mb-1 form-control mr-2"
                        value={voucherCode}
                        onChange={(e) => onChangeVoucherCodeInput(e)}
                        onClick={(e) => onChangeVoucherCodeInput(e)}
                        onFocus={() => {
                          setShowKeyboardVoucher(true);
                          setShowKeyboard(false);
                          setShowKeyboardMobile(false);
                          setshowSearchKeyboard(false);
                        }}
                        disabled={voucherDiscount > 0}
                      />

                      {voucherDiscount == 0 && (
                        <button
                          type="button"
                          className="blue-btn text-nowrap px-5 ml-2"
                          onClick={validateVoucher}
                        >
                          {t('apply').toUpperCase()}
                        </button>
                      )}
                    </section>

                    <section className="col-12">
                      {showKeyboardVoucher && (
                        <KeyboardWrapper
                          keyboardRef={keyboardVoucher}
                          setShowKeyboardVoucher={setShowKeyboardVoucher}
                          onChange={(e) => {
                            console.log('fsddfsdfsd', e);
                            setVoucherCode(e);
                          }}
                        />
                      )}
                    </section>
                  </article>
                </>
                <article className="row flex-column align-items-center">
                  <section className="col-6">
                    <h4 className="mb-2 font-weight-bold pt-4">
                      {t('checkout.Enter your contact details')}
                    </h4>
                  </section>

                  <section className="col-6">
                    <input
                      type="email"
                      placeholder="Email"
                      className="rounded-lg mb-1 form-control"
                      value={validEmail}
                      onChange={(e) => onChangeInput(e)}
                      onClick={(e) => onChangeInput(e)}
                      onFocus={() => {
                        setShowKeyboard(true);
                        setShowKeyboardVoucher(false);
                        setShowKeyboardMobile(false);
                        setshowSearchKeyboard(false);
                      }}
                    />
                  </section>

                  <section className="col-12">
                    {showKeyboard && (
                      <KeyboardWrapper
                        keyboardRef={keyboard}
                        setShowKeyboard={setShowKeyboard}
                        onChange={(e) => {
                          console.log('fsddfsdfsd', e);
                          setValidEmail(e);
                        }}
                      />
                    )}
                  </section>

                  <section className="section col-6 mb-4">
                    {errors && errors.validEmail && (
                      <span className="form-error-text d-block">
                        {t('checkout.Enter a valid email address')}
                      </span>
                    )}
                  </section>
                </article>
                <article className="row flex-column align-items-center">
                  <section className="col-6">
                    <div className="d-flex">
                      <>
                        <Dropdown
                          onClick={() => {
                            setShowKeyboard(false);

                            setShowKeyboardMobile(false);
                          }}
                          className="mr-3 code"
                        >
                          <Dropdown.Toggle
                            className="dropdownClassic"
                            name="country"
                            id="country"
                          >
                            {cust_mob_code ? cust_mob_code : 'Country Code'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdownItems">
                            <span className="d-flex flex-column align-items-center">
                              <input
                                className="kiosk-input"
                                type="number"
                                value={searchCountryCode}
                                onChange={(e) => onSearchCountry(e.target.value)}
                                placeholder="Search Country Code"
                                onChange={(e) => onChangeSearchInput(e)}
                                onClick={(e) => onChangeSearchInput(e)}
                                onFocus={() => {
                                  setshowSearchKeyboard(true);
                                  setShowKeyboard(false);
                                  setShowKeyboardMobile(false);
                                  setShowKeyboardVoucher(false);
                                }}
                              />
                              {showSearchKeyboard && (
                                <KeyboardWrapper
                                  keyboardRef={keyboardSearch}
                                  onChange={(e) => onSearchCountry(e)}
                                  keyboardOptions={keyboardOptions}
                                  setshowSearchKeyboard={setshowSearchKeyboard}
                                />
                              )}
                            </span>
                            {countriesCopy?.current.map((country) => (
                              <Dropdown.Item
                                value={country.country_id}
                                onSelect={() => onSelectCountryCode(country)}
                              >
                                {country.country_mob_code}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                        {submitted && !cust_mob_code && (
                          <span className="form-error-text d-block">
                            {t('checkout.Please select a country code')}
                          </span>
                        )}
                      </>

                      <input
                        type="number"
                        placeholder="Mobile No."
                        className="rounded-lg mb-1 form-control"
                        value={validMobile}
                        onChange={(e) => onChangeInputMobile(e)}
                        onClick={(e) => onChangeInputMobile(e)}
                        onFocus={() => {
                          setShowKeyboardMobile(true);
                          setShowKeyboard(false);
                          setshowSearchKeyboard(false);
                          setShowKeyboardVoucher(false);
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            console.log('Only numbers are allowed');
                            event.preventDefault();
                            toast.error('Only numbers are allowed', {
                              toastId: 'numeric',
                            });
                          }
                        }}
                      />
                    </div>
                  </section>
                  <section className="col-12">
                    {showKeyboardMobile && (
                      <KeyboardWrapper
                        keyboardRef={keyboardMobile}
                        enableDigitValidation={true}
                        keyboardOptions={keyboardOptions}
                        setShowKeyboardMobile={setShowKeyboardMobile}
                        onChange={(e) => {
                          console.log(e, 'fsddfsdfdfs');
                          let array = [
                            '0',
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                          ];
                          var str = e;
                          // if (str.match(/^[a-zA-Z]*$/g)) {
                          //   console.log("number only");
                          // }
                          // var res = str.replace(/\D/g, "");
                          var res1 = str.replace(/\D/g, '');
                          var res = res1.replace(/^0+/g, '');
                          // res = res.replace(/^0/g, "");
                          console.log(res, 'dfsdfsdfsd');
                          // if (array.indexOf(e.toString()) >= 0) {
                          setValidMobile(parseInt(res));
                          // }
                          // console.log("fsddfsdfsd", typeof e)
                        }}
                      />
                    )}
                  </section>
                  <section className="col-6">
                    {errors && errors.validMobile && (
                      <span className="form-error-text d-block">
                        {t('checkout.Enter a valid mobile number')}
                      </span>
                    )}
                  </section>
                </article>
              </div>
            </div>
          </div>

          <div
            className="checkout-footer-bottom"
            onClick={() => {
              setShowKeyboard(false);
              setShowKeyboardMobile(false);
            }}
          >
            <div>
              <h6 className="title">{t('fnb_page.Total Payable')}</h6>
              <h4 className="font-weight-bold">
                {curr_code} {reservationDetails.amount.toFixed(2)}{' '}
              </h4>
            </div>
            <div>
              <button className="blue-btn px-5" onClick={onProceed}>
                {t('checkout.CONFIRM & PAY')}
              </button>
            </div>
          </div>
        </div>
        {/* <div className="seat-footer col-12">
     <div className="py-4 row">
      <div className="col-md-8">
       <div className="d-flex px-3 br-1">
        <div><h4 className="f-grey">Total Payable</h4><h1 className="fw-bold">INR 320.00 </h1><h5>INR Taxes: 41.74 </h5></div></div></div><div className="d-flex justify-content-center align-items-center col-md-4">
     <span className="blue-btn btn-lg px-5">PROCEED</span></div></div></div> */}
      </Container>
      <Modal
        show={showTimeoutPopup}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-time-up"
        backdrop="static"
      >
        <Modal.Header closeButton={false}>
          <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4">
            {t('timeout.time-up')}
          </p>
        </Modal.Header>
        <Modal.Body>
          <p className="kumbh fw-reg frxl-24 frl-20 frs-16">
            {t('timeout.session-expired')}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <span
            onClick={() => history.push('/')}
            className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14"
          >
            {t('timeout.go-back')}
          </span>
        </Modal.Footer>
      </Modal>

      {/* Modal to show payment methods */}
      <Modal
        show={showPaymentModesModal}
        onHide={() => setShowPaymentModesModal(false)}
        centered
        className="modal-time-up checkout_modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton={true}>
          <p className="kumbh fw-bold frxl-36 frl-32 frm-28 frs-24 ls-4">
            {t('Select Payment Method')}
          </p>
        </Modal.Header>
        <Modal.Body className="checkout_modal__payment_modes">
          {paymentModes &&
            paymentModes.length > 0 &&
            paymentModes.map((paymentMode, index) => (
              <span
                onClick={() => paymentModeHandler(paymentMode, index)}
                className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14"
              >
                {paymentMode.pm_payment_mode.toUpperCase()}
                {loader && selectedBtn == index && (
                  <span className="pl-2">
                    <Spinner animation="border" role="status" className="spinner">
                      <span className="sr-only">{t('Loading')}</span>
                    </Spinner>
                  </span>
                )}
              </span>
            ))}
          {paymentModes.length === 0 && (
            <span className="blue-btn mxh-47 px-3 frxl-18 frl-16 frs-14">
              No payment Mode Available
            </span>
          )}

          {redirectData && redirectData?.url && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <QRCode
                level={'Q'}
                size={256}
                includeMargin={true}
                value={redirectData.url}
              />

              <p className="kumbh fw-reg lh-1 mt-2">Scan And Pay from your phone</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withNamespaces()(CheckoutOnDemand);
