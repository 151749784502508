import React, {
  Component,
  useEffect,
  useState,
  useRef,
  FunctionComponent,
  ChangeEvent,
} from 'react';
import { Container, Row, Col, Alert, Spinner, Dropdown } from 'react-bootstrap';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { globalConfigContext } from '@context/GlobalConfigContext';
import { formatYuppErrors } from '@helper/formatting';
import MovieHeader from '@components/partials/MovieHeader';
import BookingService from '@apiService/BookingService';
import { withNamespaces } from 'react-i18next';

import KeyboardWrapper from './KeyboardWrapper';

import {
  verifiyTicketNumber,
  ResetPickupTicketError,
} from '@store/booking/bookingActions';
// import { setTicketData } from '@store/booking/bookingActions';
import GoBack from '@components/partials/GoBack';
// import useCountries from "../customHooks/useCountries";

function KioskCollectLoginPage({ t }) {
  return (
    <div className="">
      <MovieHeader />
      <Container fluid>
        <GoBack to="/" />
        <LoginForm t={t} />
      </Container>
    </div>
  );
}

const LoginForm: FunctionComponent = ({ t }) => {
  // const { arabic, currency } = useContext(globalConfigContext);
  const arabic = false;
  const global = useSelector((state) => state.global);
  const {
    cinema: { cinema_id },
  } = global;
  const [ticketNumber, setTicketNumber] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [countryCode, setCountryCode] = useState(null);
  // const countries = useCountries(setCountryCode);
  const countries = {};
  const [errors, setErrors] = useState('null');
  const [showNoTicketError, setShowNoTicketError] = useState(false);
  const [showProceedSpinner, setShowProceedSpinner] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(true);
  const bookingInput = React.useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ResetPickupTicketError());
    if (bookingInput.current) {
      bookingInput.current.focus();
    }
  }, []);

  // useEffect(() => {

  //   bookingInput.current.focus();

  // }, [ticketNumber]);

  const pickup_ticket_error = useSelector(
    (state) => state.booking.pickup_ticket_error,
  );
  const schema = yup.object().shape({
    email: yup.string().email().required(),
    mobile: yup
      .string()
      .matches(/^\d{9,9}$/)
      .required(),
    countryCode: yup.string().required(),
  });

  const onSubmit = () => {
    setErrors(null);
    schema
      .validate(
        {
          email,
          mobile,
          countryCode,
        },
        { abortEarly: false },
      )
      .then(async (valid) => {
        setShowProceedSpinner(true);
        try {
          const response = await BookingService.GetTicketDetails({
            email,
            mobile: `${countryCode}${mobile}`,
          });

          if (response && response.status) {
            // dispatch(setTicketData(response.data.data));
            history.push('/kiosk-collect-2');
          }
        } catch (err) {
          setShowNoTicketError(true);
        }
        setShowProceedSpinner(false);
      })
      .catch((errs) => {
        console.log('yup errors', errs);
        setErrors(formatYuppErrors(errs));
      });
  };
  const onSubmitTicketNumber = () =>
    dispatch(verifiyTicketNumber(ticketNumber, history, cinema_id));

  // const onChangeKey = input => {
  //   // this.setState({ input });
  //   console.log("Input changed", input);
  // };
  // const onKeyPress = (button) => {
  //   console.log("Button pressed", button);

  //   /**
  //    * If you want to handle the shift and caps lock buttons
  //    */
  //   if (button === "{shift}" || button === "{lock}") this.handleShift();
  // };

  const [input, setInput] = useState('');
  const keyboard = useRef(null);

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    setTicketNumber(input);
    keyboard.current.setInput(input);
  };
  return (
    <div className="login_form">
      {showNoTicketError && (
        <Row
          className="mb-3 d-none d-md-flex sticky-alert-top"
          onClick={() => {
            setShowKeyboard(false);
          }}
        >
          <Col>
            <Alert
              variant="danger"
              onClose={() => setShowNoTicketError(false)}
              dismissible
            >
              <Alert.Heading>{t('common.imp_msg')}</Alert.Heading>
              <p>{t('collect_login.No Ticket Details Found!')}</p>
            </Alert>
          </Col>
        </Row>
      )}
      <Row
        className="bb-1"
        onClick={() => {
          setShowKeyboard(false);
        }}
      >
        <Col xs={6} className="mx-auto">
          <p className="msg">
            {t('common.Enter booking code to collect the ticket')}
          </p>
        </Col>
      </Row>
      <Row className="input-wrapper">
        <Col className="d-flex flex-column align-items-center">
          <input
            className={`kiosk-input ${pickup_ticket_error ? 'error' : ''}`}
            type="text"
            value={ticketNumber}
            placeholder={t('common.Enter booking code')}
            onChange={(e) => onChangeInput(e)}
            onClick={(e) => onChangeInput(e)}
            onFocus={() => setShowKeyboard(true)}
            ref={bookingInput}
          />

          {showKeyboard && (
            <KeyboardWrapper
              keyboardRef={keyboard}
              onChange={setTicketNumber}
              setShowKeyboard={setShowKeyboard}
            />
          )}

          <p
            className="error-p"
            onClick={() => {
              setShowKeyboard(false);
            }}
          >
            {pickup_ticket_error && (
              <span className="form-error-text d-block">
                {t('common.Enter a valid booking code')}
              </span>
            )}
          </p>
        </Col>
      </Row>
      <Row
        className="input-wrapper"
        onClick={() => {
          setShowKeyboard(false);
        }}
      >
        {/* <Col className="d-flex flex-column align-items-center">
					<input
						className={`kiosk-input ${errors && errors.mobile ? 'error' : ''}`}
						type="text"
						placeholder={t('collect_login.placeholders.mobile')}
						onChange={(e) => setMobile(e.target.value)}
					/>
					<p className="error-p">
						{errors && errors.mobile && (
							<span className="form-error-text d-block">
								{t('collect_login.errors.Enter a valid 9 digit mobile number')}
							</span>
						)}
					</p>
				</Col> */}
        {/* <Col className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-end">
            <Dropdown className="mr-4 code">
              <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
                {countryCode ? countryCode : "Loading"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdownItems">
                {countries && countries.length > 0
                  ? countries.map((country, index) => (
                      <Dropdown.Item
                        onClick={() => {
                          setCountryCode(`+${country.country_code}`);
                        }}
                        key={index}
                        className=""
                      >
                        {" "}
                        {country.country_code} ({country.country_short_code})
                      </Dropdown.Item>
                    ))
                  : null}
              </Dropdown.Menu>
            </Dropdown>
            <div className={`w-67 ${arabic ? "pr-1" : "pl-1"}`}>
              <input
                className={`kiosk-input ${
                  errors && errors.mobile ? "error" : ""
                }`}
                onChange={(e) => setMobile(e.target.value)}
                type="text"
                placeholder={t("collect_login.placeholders.mobile")}
                value={mobile}
              />
            </div>
          </div>
          {errors && errors.mobile && (
            <span className="form-error-text d-block">
              {t("collect_login.errors.Enter a valid 9 digit mobile number")}
            </span>
          )}
        </Col> */}
      </Row>
      <Row
        onClick={() => {
          setShowKeyboard(false);
        }}
      >
        <Col className="">
          <button className="blue-btn mx-auto" onClick={onSubmitTicketNumber}>
            {showProceedSpinner ? (
              <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">{t('common.Loading...')}</span>
              </Spinner>
            ) : (
              t('common.PROCEED')
            )}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default withNamespaces()(KioskCollectLoginPage);
