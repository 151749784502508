export default [
  { flag: 'us', language: 'en', title: 'English' },
  { flag: 'es', language: 'es', title: 'Español' },
];

export function getLangSpecificAttribute(
  languageSpecificImages,
  target_lang_id,
  attr,
  skip_content_langs_fallback = false,
) {
  if (!languageSpecificImages || languageSpecificImages.length === 0) return null;
  // console.log(
  //   languageSpecificImages,
  //   'languageSpecificImageslanguageSpecificImages',
  // );
  let target_obj = languageSpecificImages.find(
    (x) => x.content_lang_id === target_lang_id,
  );

  if (target_obj && target_obj[attr]) {
    return target_obj[attr];
  } else {
    if (skip_content_langs_fallback) return null;

    // check english
    let eng_obj = getLangSpecificObject(languageSpecificImages, 'english');
    if (eng_obj && eng_obj[attr]) {
      return eng_obj[attr];
    }

    // check german
    let german_obj = getLangSpecificObject(languageSpecificImages, 'german');
    if (german_obj && german_obj[attr]) {
      return german_obj[attr];
    }

    // french
    // check german
    let french_obj = getLangSpecificObject(languageSpecificImages, 'french');
    if (french_obj && french_obj[attr]) {
      return french_obj[attr];
    }

    // italian
    // check german
    let italian_obj = getLangSpecificObject(languageSpecificImages, 'italian');
    if (italian_obj && italian_obj[attr]) {
      return italian_obj[attr];
    }
  }

  if (
    !skip_content_langs_fallback &&
    languageSpecificImages.length > 0 &&
    attr === 'artwork'
  ) {
    return (
      languageSpecificImages[0]?.[attr] ||
      languageSpecificImages[1]?.[attr] ||
      languageSpecificImages[3]?.[attr] ||
      languageSpecificImages[4]?.[attr] ||
      null
    );
  }

  return null;
}

function getLangSpecificObject(arr, language = '') {
  if (arr && arr.length > 0) {
    let obj = arr.find(
      (x) => x.lang_name && x.lang_name.toLowerCase() === language.toLowerCase(),
    );

    if (obj) return obj;
    else return null;
  } else {
    return null;
  }
}
