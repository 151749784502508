import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getLangSpecificAttribute } from '@helper/Languages';
import { timeConvert } from '@helper/formatting.js';
import { motion } from 'framer-motion';
import dummyPosterStatic from '@assets/imgs/dummyPoster.png';

const MovieCard = ({ m, t, lang_id, dummy_image }) => {
  const { pathname } = useLocation();
  let [isOnDemandBooking] = useState(pathname === '/on-demand');

  const history = useHistory();
  // const { arabic, setArabic } = useContext(globalConfigContext);
  const arabic = false;
  // movie id = md_id
  // movie id2 = actual movie_id
  const onClickBookTickets = (movie_id, movie_id2, language_id) => {
    if (isOnDemandBooking)
      history.push(`/create-show-details/${movie_id}/${language_id}`);
    else history.push(`/movie-details/${movie_id}/${language_id}/${movie_id2}`);
  };

  return (
    // <div className="card_container">
    //   <div
    //     className="movie_pic"
    //     style={{
    //       backgroundImage: `url("${m.md_thumbnail_url}")`,
    //       backgroundSize: "cover",
    //       backgroundPosition: "50% 50%",
    //       backgroundRepeat: "no-repeat",
    //     }}
    //   ></div>
    //   <div className="movie_info">
    //     <div className="movie_name">{m.movie_title}</div>
    //     <div className="right_side">
    //       <div className="right_top">
    //         {m.rating} | {timeConvert(m.mrrdr_runtime)}
    //       </div>
    //       {/* <div className="right_bot">English, Arabic</div> */}
    //     </div>
    //   </div>
    //   <button
    //     onClick={() => onClickBookTickets(m.md_id, m.content_lang_id)}
    //     className="book_ticket"
    //   >
    //     {t("BOOK TICKETS")}
    //   </button>
    // </div>
    <motion.article
      className="col-md-4 col-xl-3 mt-4 mb-3 ml-col"
      onClick={() => onClickBookTickets(m.movie_id, m.md_id, m.lang_id)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="card_container">
        <div className="movie_pic">
          <img
            src={
              getLangSpecificAttribute(
                isOnDemandBooking ? m.altMovieContent : m.MovieContent,
                lang_id,
                'artwork',
              ) || dummy_image
            }
            alt=""
            onError={({ currentTarget }) => {
              currentTarget.onerror = () => {}; // prevents looping
              currentTarget.src = dummyPosterStatic;
            }}
          />
        </div>
        <div className="w-100">
          <div className="movie_info">
            {/* <div className="movie_name">{m.movie_title}</div>
                      <div className="right_side">
                          <div className="right_top">
                              {m.rating} | {timeConvert(m.run_time)}
                          </div>
                          <div className="right_bot">English, Arabic</div>
                      </div> */}
            <section className="col-6 px-2">
              <span className="text-limit v3 fw-bold frs-16">
                {arabic
                  ? m.movie_title_ar
                  : getLangSpecificAttribute(
                      isOnDemandBooking ? m.altMovieContent : m.MovieContent,
                      lang_id,
                      'mc_title',
                      true,
                    ) ||
                    m?.original_title_of_movie ||
                    m?.movie_title}
              </span>
              {/* For Language? */}
              <span className="kumbh fw-bold frs-12 text-truncate">
                {m.lang_name}
              </span>
            </section>
            <section className="col-6 px-2 d-flex justify-content-end align-items-end">
              <div className="group">
                <span
                  className={`${arabic ? 'border-left pl-1' : 'border-right pr-1'}`}
                >
                  {m.rating}{' '}
                </span>
                <span className={`${arabic ? 'pr-1' : 'pl-1'}`}>
                  {timeConvert(m.mrrdr_runtime)}
                </span>
              </div>
            </section>
          </div>
          <p
            onClick={() => onClickBookTickets(m.movie_id, m.md_id, m.lang_id)}
            className="blue-btn w-100 frs-16"
          >
            {t('movie_listing.BOOK TICKETS')}
          </p>
        </div>
      </div>
    </motion.article>
  );
};

export default withNamespaces()(MovieCard);
